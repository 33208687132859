<template>
  <el-dialog :title="popupsTitle" :visible="showState" width="1200px" top="3vh" destroy-on-close @close="hidePopups()">
    <div class="map-container">
      <div class="flex map-search">
        <el-input id="tipinput" class="map-serach-ipt" v-model="keyworkd" placeholder="请输入关键字进行搜索，坐标反查需经度在前">
          <el-button slot="append" icon="el-icon-search" @click="serachMap"></el-button>
        </el-input>
        <el-checkbox v-model="checked">坐标反查</el-checkbox>
        <div class="lngt">
          <span class="lngt-label">当前坐标点:</span>
          <el-input class="lngt-ipt" v-model="lngt" disabled placeholder="当前坐标点" />
        </div>
      </div>
      <div class="map-content" ref="map"></div>
      <div id="panel"></div>
      <p id="coords"></p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="hidePopups">取消</el-button>
        <el-button type="primary" @click="submitForm()" :loading="submitLoadingStatus">确认</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
window._AMapSecurityConfig = {
  securityJsCode: '71bc65359d2e9b34cc0b2c9bf4f24273',
};
export default {
  props: {
    popupsTitle: {
      type: String,
      default: '',
    },
    showState: {
      type: Boolean,
      default: false,
    },
    longitude: {
      type: Number,
      default: undefined,
    },
    latitude: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      submitLoadingStatus: false,
      map: null,
      keyworkd: '',
      checked: false,
      lngt: '',
      mark: null,
      placeSearch: null,
    };
  },
  watch: {
    showState(val) {
      if (val) {
        this.mapInit();
      }
    },
  },
  methods: {
    mapInit() {
      // this.nextTick(()=>{

      // })
      AMapLoader.load({
        key: '5257436a97da529dc3ebcca822a813e0', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.ToolBar', 'AMap.Geolocation', 'AMap.Geocoder', 'AMap.AutoComplete', 'AMap.PlaceSearch'],
      })
        .then((AMap) => {
          this.Amap = AMap;
          // console.log(this.latitude);
          // console.log(this.longitude);
          const mapElement = this.$refs.map;
          this.map = new AMap.Map(mapElement, {
            zoom: 12, //级别
            // center: !this.longitude && !this.latitude ? undefined : [this.latitude, this.longitude],
            center: undefined,
          });

          this.map.addControl(new AMap.ToolBar({ position: { top: '110px', right: '20px' } }));

          //定位
          let geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, //是否使用高精度定位，默认:true
            timeout: 10000, //超过10秒后停止定位，默认：无穷大
            maximumAge: 0, //定位结果缓存0毫秒，默认：0
            showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          });
          // geolocation.getCurrentPosition((status, result) => {
          //   console.log(result);
          // });

          this.map.addControl(geolocation);

          //输入框提示
          let auto = new AMap.AutoComplete({ input: 'tipinput' });
          auto.on('select', (e) => {
            // console.log(JSON.parse(JSON.stringify(e)));
            let poi = e.poi;
            this.keyworkd = poi.name;
            this.addSearch(poi.name); //添加查询列表
          }); //注册监听，当选中某条记录时会触发

          this.map.on('click', (e) => {
            this.setLngt(e.lnglat.lat, e.lnglat.lng);
          });
          this.map.on('mouseout', () => {
            this.map.clearInfoWindow();
          });

          this.map.on('mousemove', (e) => {
            var infoWindow = new AMap.InfoWindow({
              isCustom: true,
              closeWhenClickMap: true,
              autoMove: false,
              content: `<div style="padding:4px; border:1px solid #000;color:#000;background-color:#fff">${e.lnglat.lng},${e.lnglat.lat}</div>`,
              offset: [90, 30],
            });
            infoWindow.open(this.map, e.lnglat);
          });
          return;
        })
        .catch((e) => {
          // console.log(e);
        });
    },
    getLngt(address) {
      // console.log(address);
      AMap.plugin('AMap.Geocoder', () => {
        new AMap.Geocoder({ city: '010' }).getLocation(address, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            // console.log(result.geocodes);
          }
        });
      });
    },
    addSearch(name) {
      this.pageSearch = new AMap.PlaceSearch({
        //构造地点查询类
        pageSize: 10, // 单页显示结果条数
        pageIndex: 1, // 页码
        // city: '010', // 兴趣点城市
        // citylimit: true, //是否强制限制在设置的城市内搜索
        map: this.map, // 展现结果的地图实例
        panel: 'panel', // 结果列表将在此容器中进行展示。
        autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
      });
      this.pageSearch.search(name); //关键字查询
      this.pageSearch.on('selectChanged', (e) => {
        // console.log(JSON.parse(JSON.stringify(e)));
        this.setLngt(e.selected.data.location.lat, e.selected.data.location.lng);
      });
    },
    setLngt(lat, lng) {
      if (!!lat && !!lng) {
        this.lngt = lng + ',' + lat;
      } else {
        this.lngt = '';
      }
    },
    //地址逆转换
    convertAddress(lnglat) {
      return new Promise((resolve, reject) => {
        new AMap.Geocoder().getAddress(lnglat, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            resolve(result.regeocode.formattedAddress);
          }
        });
      });
    },

    addMark(location, name) {
      //将创建的点标记添加到已有的地图实例：
      if (this.mark) {
        this.map.remove(this.mark);
      }
      this.mark = new AMap.Marker({
        position: location, // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: name,
      });
      this.map.add(this.mark);
      this.map.setFitView();
    },

    async serachMap() {
      if (this.checked) {
        let lngt = this.keyworkd.split(',').map((ma) => Number(ma));
        // console.log(lngt);
        this.setLngt(lngt[1], lngt[0]);
        this.keyworkd = await this.convertAddress(lngt);
      }
      // console.log(this.keyworkd);
      this.addSearch(this.keyworkd); //添加查询列表
    },
    submitForm() {
      if (!this.lngt) {
        this.$notify.warning({ title: '提示', message: '请选择坐标点', duration: 2000 });
        return;
      }
      let lngt = this.lngt.split(',').map((ma) => Number(ma));
      this.$emit('update:longitude', lngt[0]);
      this.$emit('update:latitude', lngt[1]);
      this.hidePopups();
    },
    hidePopups() {
      // this.map.clearMap();
      // this.map.destroy();
      this.$emit('hidePopups');
      this.keyworkd = '';
      this.checked = false;
      this.lngt = '';
      this.mark = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  .map-search {
    justify-content: center;
    align-items: center;
    padding: 0 0 24px 0;
    .map-serach-ipt {
      width: 400px;
      margin-right: 16px;
    }
  }
  .lngt {
    margin: 0 0 0 150px;
    font-size: 14px;
    .lngt-label {
      margin-right: 6px;
    }
    .lngt-ipt {
      width: 250px;
    }
  }
  .map-content {
    width: 100%;
    height: 600px;
    margin-bottom: 24px;
  }
  #panel {
    position: absolute;
    top: 112px;
    right: 10px;
    width: 320px;
    max-height: 600px;
    background-color: white;
    overflow-y: auto;
  }
}
</style>
